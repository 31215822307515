import rootCalc from './Modules/root-calc';
import {
    scrollNavbar
} from './Modules/navbar';
import helpButton from './Modules/helpButton';
import LazyLoad from "vanilla-lazyload";

helpButton()
window.activeToggle = activeToggle

let lastKnownScrollPosition = 0;
let ticking = false;

document.addEventListener("scroll", (event) => {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
        window.requestAnimationFrame(() => {
            scrollNavbar(lastKnownScrollPosition);
            ticking = false;
        });

        ticking = true;
    }
});

(function () {
    function logElementEvent(eventName, element) {
        console.log(Date.now(), eventName, element.getAttribute("src"));
    }

    const callback_enter = function (element) {};
    const callback_exit = function (element) {};
    const callback_loading = function (element) {};
    const callback_loaded = function (element) {};
    const callback_error = function (element) {
        logElementEvent("💀 ERROR", element);
        element.src = "https://placehold.co/800x800?text=No+image";
    };
    const callback_finish = function () {};
    const callback_cancel = function (element) {};

    function supportsNativeLazyVideo() {
        return "loading" in HTMLVideoElement.prototype;
    }

    // Single instance using native lazy loading on images, videos and iframes
    function createSingleInstance() {
        var lazyAll = new LazyLoad({
            use_native: true,
            // NOTICE: they won't be called since the loading will be native
            callback_enter: callback_enter,
            callback_exit: callback_exit,
            callback_cancel: callback_cancel,
            callback_loading: callback_loading,
            callback_loaded: callback_loaded,
            callback_error: callback_error,
            callback_finish: callback_finish
        });
    }

    // Double instance using native lazy loading on images and iframes,
    // and JS driven lazy loading on videos
    function createDoubleInstance() {
        const lazyImgsIframes = new LazyLoad({
            elements_selector: "img.lazy, iframe.lazy",
            use_native: true,
            // Assign the callbacks defined above
            // NOTICE: they won't all called since the loading will be native
            callback_enter: callback_enter,
            callback_exit: callback_exit,
            callback_cancel: callback_cancel,
            callback_loading: callback_loading,
            callback_loaded: callback_loaded,
            callback_error: callback_error,
            callback_finish: callback_finish
        });
        const lazyVideos = new LazyLoad({
            elements_selector: "video.lazy",
            // Assign the callbacks defined above
            callback_enter: callback_enter,
            callback_exit: callback_exit,
            callback_cancel: callback_cancel,
            callback_loading: callback_loading,
            callback_loaded: callback_loaded,
            callback_error: callback_error,
            callback_finish: callback_finish
        });
    }

    if (supportsNativeLazyVideo()) {
        createSingleInstance();
    } else {
        createDoubleInstance();
    }
})();

function activeToggle(e) {
    const siblings = e.parentNode.children;

    [...siblings].forEach(item => {
        console.log(item)
        item.classList.remove('active')
    })
    e.classList.add('active')
}