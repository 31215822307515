import rootCalc from "./root-calc";

export function scrollNavbar(scrollY) {
  if (scrollY >= (window.innerHeight / 100)) {
    document.getElementById("mainNav").classList.add('active');
  } else if (scrollY <= 20) {
    document.getElementById("mainNav").classList.remove('active');
  }
}

const navbarSize = new ResizeObserver(function (entries) {
  rootCalc()
});

navbarSize.observe(document.querySelector("#mainNav"));