const root = document.querySelector(':root')
const isnavbar = !!document.querySelector('.navbar')
const navbar = document.querySelector('.navbar')
const isfooter = !!document.querySelector('.am-footer')
const footer = document.querySelector('.am-footer')
const issectionHeading = !!document.querySelector('.page-header')
const sectionHeading = document.querySelector('.page-header')

export default function rootCalc(){
    if(isnavbar)
    root.style.setProperty('--navbar-height', navbar.clientHeight + 'px');
    if(isfooter)
    root.style.setProperty('--am-footer', footer.clientHeight + 'px');
    if(issectionHeading){
        root.style.setProperty('--header-height', sectionHeading.clientHeight + 'px');
    }
}